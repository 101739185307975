import firebase from "firebase/app";
import STATICS from "../../env/static";
require("firebase/functions");
require("firebase/firestore");
require("firebase/auth");

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyBPBmQUlPSI9zFjxkbsKivr_HNlyH0o5pM",
//   authDomain: "curebayapp.firebaseapp.com",
//   projectId: "curebayapp",
//   storageBucket: "curebayapp.appspot.com",
//   messagingSenderId: "661695160924",
//   appId: "1:661695160924:web:f3d9ebbd357d9e00333c99",
//   measurementId: "G-FWKL6SLDDE",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDqfEctsU4Q5rjTlQmzasb70D9GpZbBWmI",
  authDomain: "accio-logistics-test.firebaseapp.com",
  projectId: "accio-logistics-test",
  storageBucket: "accio-logistics-test.appspot.com",
  messagingSenderId: "872021869065",
  appId: "1:872021869065:web:77386800e8ab3911e6aed8",
  measurementId: "G-WLMVZ6WG44",
};

// NOTE: To run firebase function on local machine check link -> https://firebase.google.com/docs/functions/local-emulator
export const firebaseApp = firebase.initializeApp(firebaseConfig);
// if (
//   window.location.hostname === "localhost" ||
//   window.location.hostname === "127.0.0.1" ||
//   window.location.hostname === ""
// ) {
//   firebaseApp.functions().useEmulator("localhost", 5001);
// }
export const db = firebase.firestore();
export const functions = firebaseApp.functions();
// export const getFunction =
